import "./product-specification-short.scss"

import React from "react"
import scrollToElement from "scroll-to-element"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import AddToCartCheckbox from "components/AddToCartCheckbox/AddToCartCheckbox"

const ProductSpecificationShort = ({ data, isLaptopCat }) => {
  return (
    <div className="product-specification-short">
      <PageSubHeader title="Specyfikacja produktu" />
      {!isLaptopCat ? (
        data.acfProduct.specificationOthers &&
        data.acfProduct.specificationOthers.map((item, index) => (
          <div className="product-specification-short__item" key={index}>
            <span>{item.title}</span>
            <span>{item.value}</span>
          </div>
        ))
      ) : (
        <>
          {data.paProcesors && (
            <div className="product-specification-short__item">
              <span>Procesor:</span>
              {data.paProcesors.nodes.map((item, index) => (
                <span key={index}>{item.name}</span>
              ))}
            </div>
          )}
          {data.paPamiecRams && (
            <div className="product-specification-short__item">
              <span>Pamięć RAM:</span>
              {data.paPamiecRams.nodes.map((item, index) => (
                <span key={index}>{item.name}</span>
              ))}
            </div>
          )}
          {data.paDyskTwardies && (
            <div className="product-specification-short__item">
              <span>Dysk:</span>
              {data.paDyskTwardies.nodes.map((item, index) => (
                <span key={index}>{item.name}</span>
              ))}
            </div>
          )}
          {data.paPrzekatnaEkranus && (
            <div className="product-specification-short__item">
              <span>Przekątna ekranu:</span>
              {data.paPrzekatnaEkranus.nodes.map((item, index) => (
                <span key={index}>{item.name}</span>
              ))}
            </div>
          )}
          {data.paKlasaProduktus && (
            <div className="product-specification-short__item">
              <span>Stan wizualny:</span>
              {data.paKlasaProduktus.nodes.map((item, index) => (
                <span key={index}>{item.name}</span>
              ))}
            </div>
          )}
        </>
      )}
      <a
        href="/"
        onClick={e => {
          e.preventDefault()
          scrollToElement("#specification")
        }}
      >
        Zobacz pełną specyfikację
      </a>
      {data.upsell.nodes.length > 0 && (
        <>
          <PageSubHeader title="Dodaj rozszerzenie" />
          <div className="product-specification-short">
            {data.upsell.nodes.map((item, index) => (
              <AddToCartCheckbox product={item} key={index} />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default ProductSpecificationShort
