/* eslint-disable  react-hooks/exhaustive-deps */
import "./product-recently-reviewed.scss"

import React, { useRef, useState, useEffect } from "react"
import Slider from "react-slick"

import PageHeader from "components/PageHeader/PageHeader"
import ProductTile from "components/ProductTile/ProductTile"
import { handleViewedProducts } from "utils/functions"

import IcoSlickPrev from "assets/images/ico-product-slick-prev.svg"
import IcoSlickNext from "assets/images/ico-product-slick-next.svg"

const ProductRecentlyReviewed = ({
  data,
  allViewedProducts,
  setCartConfirmation,
}) => {
  const sliderRef = useRef()
  const [viewedProducts, setViewedProducts] = useState(null)
  const [storedViewedProducts, setStoredViewedProducts] = useState(null)

  const fetchViewedProducts = () => {
    const fetchedViewed =
      JSON.parse(localStorage.getItem("viewedProducts")) || []
    setStoredViewedProducts(fetchedViewed)

    if (!fetchedViewed.includes(data.id)) {
      handleViewedProducts(
        data.id,
        storedViewedProducts,
        setStoredViewedProducts,
        fetchViewedProducts
      )
    }

    setViewedProducts(
      allViewedProducts.nodes.filter(product =>
        fetchedViewed.includes(product.id)
      )
    )
  }

  useEffect(() => {
    fetchViewedProducts()
  }, [])

  const recentlyReviewedSlider = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 750,
    slidesToShow:
      viewedProducts && viewedProducts.length < 4
        ? viewedProducts && viewedProducts.length
        : 4,
    slidesToScroll: 1,
    fade: false,
    pauseOnHover: false,

    responsive: [
      {
        breakpoint: 1365,
        settings: {
          slidesToShow:
            viewedProducts && viewedProducts.length < 3
              ? viewedProducts && viewedProducts.length
              : 3,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow:
            viewedProducts && viewedProducts.length < 2
              ? viewedProducts && viewedProducts.length
              : 2,
        },
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow:
            viewedProducts && viewedProducts.length < 1
              ? viewedProducts && viewedProducts.length
              : 1,
        },
      },
    ],
  }

  return (
    <section className="product-recently-reviewed">
      <div className="container-fluid">
        <PageHeader title="Ostatnio przeglądane" />

        <Slider ref={sliderRef} {...recentlyReviewedSlider}>
          {viewedProducts &&
            viewedProducts.map((item, index) => (
              <div key={index}>
                <ProductTile
                  id={item.id}
                  databaseId={item.databaseId}
                  image={item.image}
                  slug={item.slug}
                  title={item.name}
                  price={item.price}
                  promoPrice={item.salePrice}
                  stockQuantity={item.stockQuantity}
                  setCartConfirmation={setCartConfirmation}
                  product_data={item}
                />
              </div>
            ))}
        </Slider>

        {viewedProducts && viewedProducts.length > 4 && (
          <div className="product-recently-reviewed__nav">
            <button
              className="product-recently-reviewed__item product-recently-reviewed__nav--prev"
              onClick={() => {
                sliderRef.current.slickPrev()
              }}
            >
              <img src={IcoSlickPrev} alt="" />
              Poprzednie
            </button>
            <button
              className="product-recently-reviewed__item product-recently-reviewed__nav--next"
              onClick={() => {
                sliderRef.current.slickNext()
              }}
            >
              <img src={IcoSlickNext} alt="" />
              Następne
            </button>
          </div>
        )}
      </div>
    </section>
  )
}

export default ProductRecentlyReviewed
