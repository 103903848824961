/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */

import React, { useState, useContext, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { v4 } from "uuid"
import { toast } from "react-toastify"

import { formatPrice } from "utils/mapProductsFormAnalytics"

import { AppContext } from "components/Context/AppContext"
import CheckboxMark from "components/CheckboxMark/CheckboxMark"
import { getFormattedCart, getUpdatedItems } from "utils/functions"

import GET_CART from "queries/get-cart"
import ADD_TO_CART from "mutations/add-to-cart"
import UPDATE_CART from "mutations/update-cart"

const AddToCartCheckbox = ({ product }) => {
  const [cart, setCart] = useContext(AppContext)
  const [productAdded, setProductAdded] = useState(true)
  const [productCartKey, setProductCartKey] = useState(null)

  const productQtyInput = {
    clientMutationId: v4(),
    productId: product?.databaseId,
  }

  // Get Cart Data
  const { data, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      const updatedCart = getFormattedCart(data)
      localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart))

      setCart(updatedCart)
    },
    onError: error => {
      console.warn(error)
    },
  })

  // Add to Cart Mutation
  const [addToCart, { loading: addToCartLoading, error: addToCartError }] =
    useMutation(ADD_TO_CART, {
      variables: {
        input: productQtyInput,
      },
      onCompleted: data => {
        if (addToCartError) {
          toast.error("Wystąpił błąd")
          console.warn(addToCartError.graphQLErrors[0].message)
        } else {
          const item = data?.addToCart?.cartItem
          if (item) {
            window.fbq("track", "AddToCart", {
              content_type: "product",
              content_ids: [item.product.node.databaseId],
              value: formatPrice(item.product.node.price),
              currency: "PLN"
            });
            window.dataLayer.push({ ecommerce: null })
            window.dataLayer.push({
              event: "addToCart",
              ecommerce: {
                currencyCode: "PLN",
                add: {
                  products: [
                    {
                      name: item.product.node.name,
                      id: item.product.node.databaseId,
                      price: formatPrice(item.product.node.price),
                      brand:
                        item.product.node.paProducents?.nodes
                          ?.map(({ name }) => name)
                          .join(",") || "",
                      category:
                        item.product.node.productCategories?.nodes
                          ?.map(({ name }) => name)
                          .join(",") || "",
                      quantity: 1,
                    },
                  ],
                },
              },
            })
          }
        }

        toast.success(`Produkt został rozbudowany o ${product.name}`)
        refetch()
      },
      onError: error => {
        if (error) {
          toast.error("Wystąpił błąd")
          console.warn(error)
        }
      },
    })

  // Update Cart Mutation
  const [updateCart] = useMutation(UPDATE_CART, {
    onCompleted: () => {
      toast.warning(`Rozszerzenie ${product.name} zostało usunięte z produktu.`)
      refetch()
    },
    onError: error => {
      if (error) {
        toast.error("Wystąpił błąd")
        console.warn(error.graphQLErrors[0].messagee)
      }
    },
  })

  // Handle remove product click
  const handleRemoveProductClick = (event, cartKey, products) => {
    event.stopPropagation()
    if (products.length) {
      // By passing the newQty to 0 in updateCart Mutation, it will remove the item.
      const newQty = 0
      const updatedItems = getUpdatedItems(products, newQty, cartKey)

      updateCart({
        variables: {
          input: {
            clientMutationId: v4(),
            items: updatedItems,
          },
        },
      })
    }
  }

  // Find choosen product cartKey and match
  const findProductCartKey = (cart, product) => {
    cart &&
      cart.products.map(item => {
        item.productId === product.databaseId && setProductCartKey(item.cartKey)
      })
  }

  useEffect(() => {
    findProductCartKey(cart, product)
  }, [cart, product])

  return (
    <CheckboxMark
      name={product.databaseId}
      onChange={e => {
        setProductAdded(!productAdded)
        productAdded
          ? addToCart()
          : handleRemoveProductClick(e, productCartKey, cart.products)
      }}
    >
      {addToCartLoading
        ? "Dodawanie..."
        : `${product.name} (+${product.price})`}
    </CheckboxMark>
  )
}

export default AddToCartCheckbox
