/* eslint-disable react-hooks/exhaustive-deps */
import "./product-summary.scss"

import React, { useState, useEffect } from "react"
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api"

import Input from "components/Input/Input"

import AddToCart from "components/AddToCart/AddToCart"
import LazyPlaceholder from "components/LazyPlaceholder/LazyPlaceholder"

import IcoEnvelope from "assets/images/ico-product-summary-envelope.svg"
import IcoCheck from "assets/images/ico-product-summary-check.svg"
import IcoClose from "assets/images/ico-product-summary-close.svg"

const ProductSummary = ({
  setModal,
  data,
  cartConfirmation,
  setCartConfirmation,
  setIsAddedToCart,
}) => {
  const [quantity, setQuantity] = useState(1)
  const [apiStockStatus, setApiStockStatus] = useState(1)
  const [apiLoading, setApiLoading] = useState(true)

  const WooCommerce = new WooCommerceRestApi({
    url: "https://woo.memtech.pl",
    consumerKey: process.env.GATSBY_CONSUMER_KEY,
    consumerSecret: process.env.GATSBY_CONSUMER_SECRET,
    version: "wc/v3",
    axiosConfig: { headers: {} },
  })

  useEffect(() => {
    WooCommerce.get(`products/${data.databaseId}`)
      .then(response => {
        setApiStockStatus(response.data.stock_quantity)
        setApiLoading(false)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  return (
    <div className="product-summary">
      {apiLoading ? (
        <LazyPlaceholder />
      ) : (
        <>
          <h3>{data.price}</h3>
          <div className="product-summary__row">
            <Input
              type="number"
              name="quantity"
              label="szt"
              value={quantity}
              onChange={e =>
                setQuantity(
                  e.target.value <= 0
                    ? (e.target.value = e.target.value * -1)
                    : e.target.value
                )
              }
              step="1"
              min="1"
              required
            />
            <AddToCart
              product={data}
              quantity={quantity}
              onClick={() => setIsAddedToCart(true)}
              cartConfirmation={cartConfirmation}
              setCartConfirmation={setCartConfirmation}
              disabled={
                quantity <= 0 || (quantity > apiStockStatus && "disabled")
              }
            />
          </div>
          <div className="product-summary__leasing">
            <a
              href="https://leaselink.pl/shortH/co/63c7b0"
              target="_blank"
              rel="noreferrer"
              className="button button--bordered"
            >
              <span>Leasing</span>
            </a>
          </div>
          <div className="product-summary__info">
            {apiStockStatus > 0 ? (
              <>
                <p>
                  <img src={IcoCheck} alt="" />
                  Dostępna ilość: {apiStockStatus && apiStockStatus} szt.
                </p>
                <p>
                  <img src={IcoCheck} alt="" />
                  Darmowa dostawa od 2000zł
                </p>
                <p>
                  <img src={IcoCheck} alt="" />
                  Dostawa za 2 dni
                </p>
              </>
            ) : (
              <p>
                <img src={IcoClose} alt="" />
                Produkt niedostępny
              </p>
            )}
          </div>
          <a
            className="product-summary__cta"
            href="/"
            onClick={e => {
              e.preventDefault()
              setModal(true)
            }}
          >
            <img src={IcoEnvelope} alt="" />
            Zapytaj o produkt
          </a>
        </>
      )}
    </div>
  )
}

export default ProductSummary
