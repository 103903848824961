/* eslint-disable jsx-a11y/alt-text */
import "./product-info.scss"

import React from "react"
import { useComparedProducts } from "context/compared_products"
import { useFavoriteProducts } from "context/favorite_products"
import PageHeader from "components/PageHeader/PageHeader"

const ProductInfo = ({ title, data }) => {
  const {
    local_storage_favorites_products,
    setProductToFavorites,
  } = useFavoriteProducts()
  const {
    local_storage_compared_products,
    setProductToCompare,
  } = useComparedProducts()

  return (
    <section className="product-info">
      <div className="container-fluid">
        <PageHeader title={title} />
        <div className="row">
          <div className="col-md-9">
            <div className="product-info__wrapper">
              <div className="product-info__stars">
                <img
                  src={
                    require(`assets/images/ico-product-star-${
                      data.averageRating >= 1 ? "filled" : "empty"
                    }.svg`).default
                  }
                />
                <img
                  src={
                    require(`assets/images/ico-product-star-${
                      data.averageRating >= 2 ? "filled" : "empty"
                    }.svg`).default
                  }
                />
                <img
                  src={
                    require(`assets/images/ico-product-star-${
                      data.averageRating >= 3 ? "filled" : "empty"
                    }.svg`).default
                  }
                />
                <img
                  src={
                    require(`assets/images/ico-product-star-${
                      data.averageRating >= 4 ? "filled" : "empty"
                    }.svg`).default
                  }
                />
                <img
                  src={
                    require(`assets/images/ico-product-star-${
                      data.averageRating >= 5 ? "filled" : "empty"
                    }.svg`).default
                  }
                />
                <span>({data.reviewCount})</span>
              </div>
              {data.paProducents.nodes.length > 0 && (
                <p className="product-info__text">
                  Producent:{" "}
                  {data.paProducents.nodes.map((item, index) => (
                    <span key={index}>{item.name}</span>
                  ))}
                </p>
              )}
              {data.sku && (
                <p className="product-info__text">Kod produktu: {data.sku}</p>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="product-info__wrapper product-info__wrapper--right">
              <button
                className={
                  local_storage_compared_products.indexOf(data.id) > -1
                    ? "is-compared"
                    : ""
                }
                onClick={() => setProductToCompare(data.id)}
              >
                <img
                  src={
                    require("assets/images/ico-product-tile-clipboard.svg")
                      .default
                  }
                  alt=""
                />
              </button>
              <button
                className={
                  local_storage_favorites_products.indexOf(data.id) > -1
                    ? "is-favourite"
                    : ""
                }
                onClick={e => {
                  e.preventDefault()
                  setProductToFavorites(data.id)
                }}
              >
                <img
                  src={
                    require("assets/images/ico-product-tile-heart.svg").default
                  }
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductInfo
