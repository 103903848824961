import "./product-gallery.scss"

import React, { useState } from "react"
import Slider from "react-slick"
import { withPrefix } from "gatsby"

import PageHeader from "components/PageHeader/PageHeader"

const ProductGallery = ({ title, gallery, setGallery, data }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()

  const productGallerySlider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
    afterChange: function (index) {
      setCurrentSlide(index)
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          adaptiveHeight: true,
        },
      },
    ],
  }

  const productGalleryNav = {
    slidesToShow: data.galleryImages.nodes.length,
    swipeToSlide: true,
    focusOnSelect: true,
  }

  return (
    <>
      <div className="product-gallery">
        <button
          className="product-gallery__thumb"
          onClick={() => setGallery(true)}
        >
          {data.image ? (
            <img
              className="img-fluid"
              src={data.image?.localFile?.publicURL}
              alt={title}
            />
          ) : (
            <img
              className="product-tile__image--placeholder"
              src={withPrefix("/ico-product-laptop-placeholder.svg")}
              alt="Memtech produkt placeholder"
            />
          )}
        </button>
        <div className="product-gallery__thumb-mobile-dots">
          {Array(data.galleryImages.nodes.length)
            .fill(null)
            .map((item, index) => (
              <span key={index} />
            ))}
        </div>

        {data.galleryImages && (
          <div className="product-gallery__nav">
            {data.galleryImages.nodes.slice(0, 4).map((item, index) => (
              <button
                className="product-gallery__nav-item"
                onClick={() => setGallery(true)}
                key={index}
              >
                <img
                  className="img-fluid"
                  src={item?.localFile?.publicURL}
                  alt=""
                />
              </button>
            ))}
            {data.galleryImages.nodes.length > 4 && (
              <button
                className="product-gallery__nav-item"
                onClick={() => setGallery(true)}
              >
                <p>+{data.galleryImages.nodes.length - 4}</p>
              </button>
            )}
          </div>
        )}
      </div>

      {gallery && (
        <div className="product-gallery-popup">
          <div className="container-fluid">
            <div className="product-gallery-popup__header">
              <PageHeader title={title} />
              <button
                onClick={() => setGallery(false)}
                className="product-gallery-popup__close"
              >
                Close
              </button>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-10">
                <Slider
                  asNavFor={nav2}
                  ref={slider1 => setNav1(slider1)}
                  {...productGallerySlider}
                >
                  {data.galleryImages.nodes.map((item, index) => (
                    <div key={index}>
                      <div className="product-gallery-popup__thumb">
                        <img
                          className="img-fluid"
                          src={item?.localFile?.publicURL}
                          alt=""
                        />
                      </div>
                    </div>
                  ))}
                </Slider>

                <div className="product-gallery-popup__row">
                  <Slider
                    asNavFor={nav1}
                    ref={slider2 => setNav2(slider2)}
                    {...productGalleryNav}
                  >
                    {data.galleryImages.nodes.map((item, index) => (
                      <div key={index}>
                        <div className="product-gallery-popup__nav-item">
                          <img
                            className="img-fluid"
                            src={item?.localFile?.publicURL}
                            alt=""
                          />
                        </div>
                      </div>
                    ))}
                  </Slider>
                  <div className="product-gallery-popup__indicators">
                    <span>{currentSlide + 1}</span>
                    <p>{data.galleryImages.nodes.length}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ProductGallery
