import "./product-modal.scss"

import React, { useState } from "react"
import axios from "axios"
import { toast } from "react-toastify"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import Input from "components/Input/Input"
import Textarea from "components/Textarea/Textarea"
import Checkbox from "components/Checkbox/Checkbox"
import Button from "components/Button/Button"

const ProductModal = ({ title, setModal }) => {
  const isBrowser = typeof window !== "undefined"

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [phone, setPhone] = useState("")
  const [legal, setLegal] = useState(false)

  const resetForm = () => {
    setName("")
    setEmail("")
    setPhone("")
    setMessage("")
    setLegal(false)
  }

  const formSubmit = e => {
    e.preventDefault()

    let formData = new FormData()

    formData.set("firstName", name)
    formData.set("email", email)
    formData.set("phone", phone)
    formData.set("product", title)
    formData.set("productUrl", isBrowser ? window.location.href : "")
    formData.set("message", message)
    formData.set("legal", legal)

    axios({
      method: "post",
      url: "https://woo.memtech.pl/wp-json/contact-form-7/v1/contact-forms/58/feedback/",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res => {
        resetForm()
        setModal(false)
        toast.success("Wiadomość została wysłana.")
      })
      .catch(() => {
        toast.error("Wystąpił problem. Spróbuj ponownie później.")
      })
  }

  return (
    <div className="product-modal">
      <div className="product-modal__wrapper">
        <PageSubHeader title={"Zapytanie o produkt " + title} />
        <button
          className="product-modal__close"
          onClick={() => setModal(false)}
        >
          Close
        </button>
        <form onSubmit={formSubmit}>
          <div className="row">
            <div className="col-sm-12">
              <Input
                placeholder="Imię"
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div className="col-sm-6">
              <Input
                placeholder="E-mail"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="col-sm-6">
              <Input
                placeholder="Telefon"
                type="tel"
                id="phone"
                name="phone"
                value={phone}
                onChange={e => setPhone(e.target.value)}
              />
            </div>
            <div className="col-sm-12">
              <Textarea
                placeholder="Wiadomość"
                id="message"
                value={message}
                onChange={e => setMessage(e.target.value)}
                rows="5"
              />
            </div>
          </div>
          <Checkbox
            name="legal"
            checked={legal}
            onChange={() => setLegal(!legal)}
            required
          >
            Chcę zadać pytanie dotyczące wybranego produktu w ofercie firmy
            MEMTECH Sp. z o.o. Sp. k. i akceptuję politykę prywatności.
          </Checkbox>
          <div className="text-center">
            <Button type="submit" disabled={!legal}>
              Wyślij
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProductModal
