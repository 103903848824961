/* eslint-disable jsx-a11y/alt-text */
import "./product-opinions.scss"

import React from "react"

import PageHeader from "components/PageHeader/PageHeader"
import IcoUser from "assets/images/ico-product-opions-user.svg"

const ProductOpinions = ({ data }) => {
  return (
    <section className="product-opinions">
      <div className="container-fluid">
        <PageHeader title="Opinie" />

        <div className="product-opinions__nav">
          <p>Średnia ocena produktu:</p>
          <strong>{data.averageRating}/5</strong>
          <div className="product-opinions__stars">
            <img
              src={
                require(`assets/images/ico-product-star-${
                  data.averageRating >= 1 ? "filled" : "empty"
                }.svg`).default
              }
            />
            <img
              src={
                require(`assets/images/ico-product-star-${
                  data.averageRating >= 2 ? "filled" : "empty"
                }.svg`).default
              }
            />
            <img
              src={
                require(`assets/images/ico-product-star-${
                  data.averageRating >= 3 ? "filled" : "empty"
                }.svg`).default
              }
            />
            <img
              src={
                require(`assets/images/ico-product-star-${
                  data.averageRating >= 4 ? "filled" : "empty"
                }.svg`).default
              }
            />
            <img
              src={
                require(`assets/images/ico-product-star-${
                  data.averageRating >= 5 ? "filled" : "empty"
                }.svg`).default
              }
            />
            <span>({data.reviewCount})</span>
          </div>
        </div>

        {data.reviews.nodes.map((item, index) => (
          <div className="product-opinions__item" key={index}>
            <div className="row">
              <div className="col-md-2 offset-md-1">
                <div className="product-opinions__item-user">
                  <div
                    className="product-opinions__item-image"
                    style={{ backgroundImage: `url(${IcoUser})` }}
                  ></div>
                  <p>{item.author.node.name}</p>
                </div>
                <div className="product-opinions__item-date">
                  Data dodania: {item?.date?.split(" ")?.[0]}
                </div>
              </div>
              <div className="col-md-8">
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
                <div className="product-opinions__item-stars">
                  <p>Ocena: </p>
                  {Array(5)
                    .fill(null)
                    .map((item, index) => (
                      <img
                        key={index}
                        src={
                          require("assets/images/ico-product-star-filled.svg")
                            .default
                        }
                        alt=""
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default ProductOpinions
