import "./product-description.scss"

import React from "react"

import PageHeader from "components/PageHeader/PageHeader"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import ProductTooltip from "./components/ProductDescriptionTooltip"

const ProductDescription = ({ data }) => {
  return (
    <section className="product-description">
      <div className="container-fluid">
        <PageHeader title="Opis" />
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <PageSubHeader title="Opis" />
            <div
              dangerouslySetInnerHTML={{
                __html: data.acfProduct.mainDescription,
              }}
            />
          </div>

          <div className="col-lg-3 offset-lg-1 col-md-4">
            <PageSubHeader title="Stan produktu" />
            <ul>
              {data?.acfProduct?.technicalStatus && (
                <li>
                  <span>Stan techniczny:</span>{" "}
                  {data?.acfProduct?.technicalStatus}
                </li>
              )}
              {data?.paKlasaProduktus?.nodes.length > 0 && (
                <li>
                  <span>Stan wizualny:</span> Klasa{" "}
                  {data?.paKlasaProduktus.nodes.map((item, index) => (
                    <i key={index}>
                      {item.name} <ProductTooltip type={item.name} />
                    </i>
                  ))}
                </li>
              )}
              {data.acfProduct.visualStatusDescription && (
                <li>{data.acfProduct.visualStatusDescription}</li>
              )}
            </ul>

            <PageSubHeader
              title="Gwarancja"
              className="page__sub-header--less-m"
            />
            <p>{data?.acfProduct?.guarantee}</p>
          </div>

          <div className="col-lg-3 offset-lg-1 col-md-4">
            <PageSubHeader title="Pochodzenie" />
            {data?.acfProduct?.descriptionOrigin ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.acfProduct?.descriptionOrigin,
                }}
              />
            ) : (
              <>
                <p>
                  Sprzedawany przez nas sprzęt jest produktem używanym,
                  poleasingowym.
                </p>
                <p>
                  Towar przeszedł gruntowne testy, co daje nam gwarancje 100%
                  sprawności technicznej.
                </p>
                <p>
                  Laptop jest przygotowany pod klienta, instalowany jest
                  oryginalny system operacyjny oraz wgrywane najnowsze
                  sterowniki.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductDescription
