import "./product-specification.scss"

import React from "react"

import PageHeader from "components/PageHeader/PageHeader"

const ProductSpecification = ({ data, isLaptopCat }) => {
  return (
    <section className="product-specification">
      <div className="container-fluid">
        <PageHeader title="Specyfikacja" />

        {isLaptopCat && (
          <>
            <div className="product-specification__group">
              <h4>Procesor</h4>
              {data.acfProduct.procesorModel && (
                <div className="product-specification__item">
                  <p>Model:</p>
                  <p>{data.acfProduct.procesorModel}</p>
                </div>
              )}
              {data.acfProduct.procesorLiczbaRdzeni && (
                <div className="product-specification__item">
                  <p>Liczba rdzeni:</p>
                  <p>{data.acfProduct.procesorLiczbaRdzeni}</p>
                </div>
              )}
              {data.acfProduct.procesorLiczbaWatkow && (
                <div className="product-specification__item">
                  <p>Liczba wątków:</p>
                  <p>{data.acfProduct.procesorLiczbaWatkow}</p>
                </div>
              )}
              {data.acfProduct.procesorBazoweTaktowanie && (
                <div className="product-specification__item">
                  <p>Bazowe taktowanie procesora:</p>
                  <p>{data.acfProduct.procesorBazoweTaktowanie}</p>
                </div>
              )}
              {data.acfProduct.procesorTurboTaktowanie && (
                <div className="product-specification__item">
                  <p>Turbo taktowanie procesora:</p>
                  <p>{data.acfProduct.procesorTurboTaktowanie}</p>
                </div>
              )}
            </div>

            <div className="product-specification__group">
              <h4>Pamięć RAM</h4>
              {data.acfProduct.pamiecRamIlosc && (
                <div className="product-specification__item">
                  <p>Ilość:</p>
                  <p>{data.acfProduct.pamiecRamIlosc}</p>
                </div>
              )}
              {data.acfProduct.pamiecRamRodzajPamieci && (
                <div className="product-specification__item">
                  <p>Rodzaj pamięci:</p>
                  <p>{data.acfProduct.pamiecRamRodzajPamieci}</p>
                </div>
              )}
              {data.acfProduct.pamiecRamTaktowanie && (
                <div className="product-specification__item">
                  <p>Taktowanie:</p>
                  <p>{data.acfProduct.pamiecRamTaktowanie}</p>
                </div>
              )}
            </div>

            <div className="product-specification__group">
              <h4>Dysk twardy</h4>
              {data.acfProduct.dyskTwardyRodzaj && (
                <div className="product-specification__item">
                  <p>Rodzaj pamięci:</p>
                  <p>{data.acfProduct.dyskTwardyRodzaj}</p>
                </div>
              )}
              {data.acfProduct.dyskTwardyWielkosc && (
                <div className="product-specification__item">
                  <p>Wielkość:</p>
                  <p>{data.acfProduct.dyskTwardyWielkosc}</p>
                </div>
              )}
            </div>

            {data.acfProduct.kartaGraficzna && (
              <div className="product-specification__group">
                <h4>Karta graficzna</h4>
                <div className="product-specification__item">
                  <p>Model:</p>
                  <p>{data.acfProduct.kartaGraficzna}</p>
                </div>
              </div>
            )}

            <div className="product-specification__group">
              <h4>Matryca</h4>
              {data.acfProduct.matrycaRozdzielczosc && (
                <div className="product-specification__item">
                  <p>Rozdzielczość:</p>
                  <p>{data.acfProduct.matrycaRozdzielczosc}</p>
                </div>
              )}
              {data.acfProduct.matrycaTyp && (
                <div className="product-specification__item">
                  <p>Typ:</p>
                  <p>{data.acfProduct.matrycaTyp}</p>
                </div>
              )}
              {data.acfProduct.matrycaRodzaj && (
                <div className="product-specification__item">
                  <p>Rodzaj:</p>
                  <p>{data.acfProduct.matrycaRodzaj}</p>
                </div>
              )}
            </div>
          </>
        )}

        {data.acfProduct.specificationOthers && (
          <div className="product-specification__group">
            <h4>Pozostałe</h4>
            {data.acfProduct.specificationOthers.map((item, index) => (
              <div className="product-specification__item" key={index}>
                <p>{item.title}</p>
                <p>{item.value}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

export default ProductSpecification
