import "./product-nav.scss"

import React from "react"
import scrollToElement from "scroll-to-element"

import IcoNav1 from "assets/images/ico-product-nav-1.svg"
import IcoNav2 from "assets/images/ico-product-nav-2.svg"
import IcoNav3 from "assets/images/ico-product-nav-3.svg"

const ProductNav = () => {
  return (
    <section className="product-nav">
      <div className="container-fluid">
        <div className="product-nav__wrapper">
          <p>Przejdź bezpośrednio do sekcji:</p>
          <button onClick={() => scrollToElement("#description")}>
            <img src={IcoNav1} alt="" /> Opis
          </button>
          <button onClick={() => scrollToElement("#specification")}>
            <img src={IcoNav2} alt="" /> Specyfikacja
          </button>
          <button onClick={() => scrollToElement("#opinions")}>
            <img src={IcoNav3} alt="" /> Opinie
          </button>
        </div>
      </div>
    </section>
  )
}

export default ProductNav
